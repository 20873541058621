export const AccountErrors = {
    badIdentifiersError: 'bad_identifiers_error',
    passwordInvalidError: 'password_invalid',
    passwordRequiredMessage: 'password_required',
    retypePasswordRequiredMessage: 'retype_password_required',
    currentPasswordRequiredMessage: 'current_password_required',
    newPasswordRequiredMessage: 'new_password_required',
    newPasswordValidMessage: 'new_password_invalid',
    invalidSignInErrorMessage: 'invalid_sign_in',
    accountLockedResetPasswordError: 'account_locked_reset_password',
    signInAccountUnrecognized: 'account_unrecognized',
    facebookAuthError: 'facebook_auth_error',
    facebookAuthErrorUserDenied: 'facebook_auth_error_user_denied',
    facebookAuthErrorScopesDenied: 'facebook_auth_error_scopes_denied',
    facebookAuthErrorScopeEmail: 'facebook_auth_error_scope_email',
    signInAccountLocked4AttemptsError: 'account_locked_4_attempts',
    signInAccountLocked5AttemptsError: 'account_locked_5_attempts',
    signInAccountLocked6AttemptsError: 'account_locked_6_attempts',
    passwordStrengthFailedResetPasswordError: 'password_strength_failed_reset_password',
    emailRequiredMessage: 'email_required',
    emailValidMessage: 'email_invalid',
    emailAlreadyUsedMessage: 'email_already_used',
    sessionExpiredError: 'session_expired',
    forgotPasswordEmailNotFound: 'forgot_password_email_not_found',
    confirmPasswordValidationMessage: 'confirm_password_validation',
    resetPasswordAlreadyUsedMessage: 'reset_password_already_used',
    resetPasswordLinkExpiredError: 'reset_password_link_expired',
    resetPasswordLinkInvalidError: 'reset_password_link_invalid',
    passwordResetLimitExceeded: 'reset_password_limit_exceed',
    maintenanceError: 'maintenance_error',
    birthdayValidMessage: 'birthday_invalid',
    birthdayIsRequiredLabel: 'birthday_required',
    birthdayChangeError: 'birthday_change_error',
    nationalIdInvalidError: 'national_id_invalid',
    newEmailDifferentMessage: 'new_email_different',
    newPasswordDifferentMessage: 'new_password_different',
    confirmEmailValidationMessage: 'confirm_email_invalid',
    emailAlreadyUsedErrorMessage: 'email_already_used',
    incorrectPassword: 'incorrect_password',
    invalidPassword: 'invalid_password',
    addressRequiredMessage: 'address_required',
    addAddressInvalidErrorMessage: 'add_address_invalid',
    countryRequiredMessage: 'country_required',
    countryValidMessage: 'country_invalid',
    zipCodeRequiredMessage: 'zip_code_required',
    zipCodeValidMessage: 'zip_code_invalid',
    cityRequiredMessage: 'city_required',
    cityValidMessage: 'city_invalid',
    stateRequiredMessage: 'state_required',
    stateValidMessage: 'state_invalid',
    secondLineAddressRequiredMessage: 'second_line_address_required',
    secondLineAddressInvalidMessage: 'second_line_address_invalid',
    thirdLineAddressRequiredMessage: 'third_line_address_required',
    thirdLineAddressInvalidMessage: 'third_line_address_invalid',
    fourthLineAddressRequiredMessage: 'fourth_line_address_required',
    fourthLineAddressInvalidMessage: 'fourth_line_address_invalid',
    fifthLineAddressRequiredMessage: 'fifth_line_address_required',
    fifthLineAddressInvalidMessage: 'fifth_line_address_invalid',
    mobileNumberRequiredMessage: 'mobile_number_required',
    mobileNumberValidMessage: 'mobile_number_invalid',
    companyRequiredMessage: 'company_required',
    companyValidMessage: 'company_invalid',
    photoFormatNotSupported: 'photo_format_not_supported',
    photoSizeLimitExceeded: 'photo_size_limit_exceeded',
    genericPhotoUploadError: 'generic_photo_upload_error',
    preferredLanguageRequiredMessage: 'preferred_language_required',
    agreeRequiredMessage: 'agree_required',
    alreadyRegisteredMessage: 'already_registered',
    mobileNumberRegistered: 'mobile_number_registered',
    mobileNumberIncorrect: 'mobile_number_incorrect',
    smsSendFailure: 'sms_send_failure',
    smsCodeInvalid: 'sms_code_invalid',
    smsCodeRequired: 'sms_code_required',
    smsCodeExpired: 'sms_code_expired',
    smsCodeLimitationExceeded: 'sms_code_limitation_exceeded',
    invalidSavedBillingAddressErrorMessage: 'invalid_saved_billing_address',
    smsAccountError: 'sms_account_error',
    usernameRequired: 'username_required',
    phoneNumberNotRegisterError: 'phone_number_not_register_error',
    userLockedError: 'user_locked_error',
    passwordCheckError: 'password_check_error',
    verificationCodeApiError: 'verification_code_api_error',
    verificationCodeInvalidationError: 'verification_code_invalidation_error',
    userExistsError: 'user_exists_error',
    districtRequiredMessage: 'district_required',
    districtInvalidMessage: 'district_invalid',
    postalCodeRequiredMessage: 'postal_code_required',
    postalCodeInvalidMessage: 'postal_code_invalid',
    signInFailureErrorMessage: 'sign_in_failure',
    posGenericErrorMessage: 'pos_generic_error',
    posNoTransactionMessage: 'pos_no_transaction',
    completeSignUpGenericErrorMessage: 'complete_signup_generic',
    partialSignUpGenericErrorMessage: 'partial_signup_generic',
    completeUserExistsErrorMessage: 'complete_user_exists',
    partialUserExistsErrorMessage: 'partial_user_exists'
};
