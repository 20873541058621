export const Offers = {
    offer_success: 'offer_success',
    offer_failure: 'offer_failure',
    offer_invalid: 'offer_invalid',
    offer_expired: 'offer_expired',
    offer_conflict: 'offer_conflict',
    offer_inapplicable: 'offer_inapplicable',
    best_discount_applied: 'best_discount_applied',
    offer_removed: 'offer_removed',
    samplesAndOffersLabel: 'samples_and_offers',
    congratulationsOffersLabel: 'congratulations_offer',
    qualifiedOffersLabel: 'qualified_offers_title',
    notQualifiedOffersLabel: 'not_qualified_offers_title',
    removeOfferLabel: 'remove_offer',
    applyOfferLabel: 'apply_offer'
};
