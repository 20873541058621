export const Orders = {
    trackMyOrder: 'track_my_order',
    trackMyOrderGuidance: 'track_my_order_guidance',
    pleaseInputYourOrderNumber: 'please_input_your_order_number',
    trackOrder: 'track_order',

    orderProcessing: 'order_processing',
    yourOrderIsProcessing: 'your_order_is_processing',
    orderStatusProcessingInstruction: 'order_status_processing_instruction',
    orderStatusProcessingMessage: 'order_status_processing_message',

    atWarehouse: 'at_warehouse',
    yourOrderIsAtWarehouse: 'your_order_is_at_warehouse',
    orderStatusAtWarehouseInstruction: 'order_status_at_warehouse_instruction',
    orderStatusAtWarehouseMessage: 'order_status_at_warehouse_message',

    shipped: 'shipped',
    yourOrderIsShipped: 'your_order_is_shipped',
    orderStatusShippedInstruction: 'order_status_shipped_instruction',
    orderStatusShippedMessage: 'order_status_shipped_message',

    delivered: 'delivered',
    yourOrderIsDelivered: 'your_order_is_delivered',
    orderStatusDeliveredInstruction: 'order_status_delivered_instruction',
    orderStatusDeliveredMessage: 'order_status_delivered_message',

    returnInProgress: 'return_in_progress',
    yourOrderIsReturnInProgress: 'your_order_is_return_in_progress',
    orderStatusReturnInProgressInstruction: 'order_status_return_in_progress_instruction',
    orderStatusReturnInProgressMessage: 'order_status_return_in_progress_message',

    cancelled: 'cancelled',
    yourOrderIsCancelled: 'your_order_is_cancelled',
    orderStatusCancelledInstruction: 'order_status_cancelled_instruction',
    orderStatusCancelledMessage: 'order_status_cancelled_message',

    orderNumberInvalidErrorMessage: 'order_number_invalid',
    noOrderFoundErrorMessage: 'no_order_found',
    orderTrackingGenericErrorMessage: 'order_tracking_generic_error',

    deliveryMethodLabel: 'delivery_method',
    orderStatusExplainedLabel: 'order_status_explained',
    shippingInformationLabel: 'shipping_information',
    carrierNameLabel: 'carrier_name',
    shippedDateLabel: 'shipped_date',
    trackingNumberLabel: 'tracking_number'
};
