export const User = {
    emailLabel: 'email',
    usernameLabel: 'username',
    firstNameLabel: 'first_name',
    lastNameLabel: 'last_name',
    firstNameAlternateLabel: 'first_name_alternate',
    lastNameAlternateLabel: 'last_name_alternate',
    fullNameLabel: 'full_name',
    birthdayLabel: 'birthday',
    salutationLabel: 'salutation',
    birthYearLabel: 'birth_year',
    birthMonthLabel: 'birth_month',
    birthDayLabel: 'birth_day',
    ageGroupLabel: 'age_group',
    customerLocationLabel: 'customer_location',
    countryCodeLabel: 'country_code',
    missSalutation: 'miss_salutation',
    msSalutation: 'ms_salutation',
    mrsSalutation: 'mrs_salutation',
    mrSalutation: 'mr_salutation',
    sexLabel: 'sex',
    areaCodeLabel: 'area_code',
    homePhoneLabel: 'home_phone',
    mobileEmailLabel: 'mobile_email',
    primaryPhoneLabel: 'primary_phone',
    secondaryPhoneLabel: 'secondary_phone'
};
