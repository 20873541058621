import { queryProvider, serviceQueries } from '@estee/elc-service-bus';
import { TranslationsRepository } from '../internal/repositories/TranslationsRepository';

export class ServiceBusController {
    // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    constructor(private translationsRepository: TranslationsRepository) {}
    @queryProvider(serviceQueries.GET_TRANSLATIONS)
    public loadTranslations = async (payload: string[]) => {
        const translations = await this.translationsRepository.getTranslations(payload);

        return translations;
    };
}
