export const Stores = {
    application: 'application',
    apply: 'apply',
    available: 'available',
    availableAt: 'available_at',
    bookAppointment: 'book_appointment',
    callForAvailability: 'call_for_availability',
    chooseAStore: 'choose_a_store',
    collectInStore: 'collect_in_store',
    complimentaryEngraving: 'complimentary_engraving',
    contactStore: 'contact_store',
    doorTypeAirportDutyFreeStore: 'airport_duty_free_store',
    doorTypeDepartmentStore: 'department_store',
    doorTypeDutyFreeStore: 'duty_free_store',
    doorTypeFreeStandingStore: 'free_standing_store',
    doorTypePopupStore: 'popup_store',
    filters: 'filters',
    findInStore: 'find_in_store',
    findStore: 'find_store',
    getDirections: 'get_directions',
    goToDirectoryLabel: 'go_to_directory',
    hours: 'hours',
    inStoreServices: 'in_store_services',
    locations: 'stores_found',
    moveTheMap: 'move_the_map',
    nearby: 'nearby',
    noFilterResultMessage: 'no_filter_result_message',
    notAvailable: 'not_available',
    openNow: 'open_now',
    openTodayUntil: 'open_today_until',
    openUntil: 'open_until',
    otherNearbyLocations: 'other_neraby_locations',
    pickUpInStore: 'pick_up_in_store',
    pickUpInStoreAddItemsToBagDescription: 'find_and_add_to_bag',
    pickupNotAvailable: 'pickup_not_available',
    pickUpInStoreAddItemsToBagTitle: 'add_items_to_bag',
    pickUpInStoreCheckoutDescription: 'click_buy_online_pick_up_in_store_and_select',
    pickUpInStoreCheckoutTitle: 'checkout',
    pickUpInStoreInformationDescription: 'currently_available_at',
    pickUpInStoreInformationTitle: 'buy_online_pick_up_in_store',
    pickUpInStoreNotificationDescription: 'pick_up_in_store_info_third_step_description',
    pickUpInStoreNotificationTitle: 'notification_and_pick_up',
    pickUpInStoreWantItToday: 'want_it_today',
    searchPlaceholder: 'search_placeholder',
    searchThisArea: 'serach_this_area',
    seeAvailability: 'see_availability',
    selectedStore: 'selected_store',
    storeClosed: 'store_closed',
    storeClosingHour: 'store_closing_hour',
    storeDetails: 'store_details',
    storeDetailsMapAlt: 'static_map_alt_text',
    storeDirectory: 'store_directory',
    storeLabel: 'store',
    storeList: 'store_list',
    storeLocator: 'store_locator',
    storeOpen: 'store_open',
    storeOpeningHour: 'store_opening_hour',
    storeOpeningHourFriday: 'opens_on_friday',
    storeOpeningHourMonday: 'opens_on_monday',
    storeOpeningHourSaturday: 'opens_on_saturday',
    storeOpeningHourSunday: 'opens_on_sunday',
    storeOpeningHourThursday: 'opens_on_thursday',
    storeOpeningHourToday: 'opens_today',
    storeOpeningHourTuesday: 'opens_on_tuesday',
    storeOpeningHourWednesday: 'opens_on_wednesday',
    storeOpeningIsClosed: 'store_is_closed',
    stores: 'stores',
    storesDirectoryCTA: 'stores_directory_cta',
    storesDirectorySubtitle: 'stores_directory_subtitle',
    storesDirectoryTitle: 'stores_directory_title',
    storeServicesSubtitle: 'in_store_services_subtitle',
    storeServicesTitle: 'in_store_services_title',
    storesFound: 'stores_found',
    storesHome: 'stores_home',
    storesLandingSubtitle: 'stores_landing_subtitle',
    storesLandingTitle: 'stores_landing_title',
    today: 'today',
    viewAmountUpcomingEvents: 'view_amount_upcoming_events',
    yourStore: 'your_store'
};
