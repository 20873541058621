export const Account = {
    passwordLabel: 'password',
    signUpLabel: 'sign_up',
    signUpTabTitle: 'sign_up_tab_title',
    signUpTitle: 'sign_up_title',
    termsAndConditionsLabel: 'terms_and_conditions',
    emailSubscriptionLabel: 'email_subscription',
    emailSubscriptionDisclaimer: 'email_subscription_disclaimer',
    existingAccountLabel: 'existing_account',
    signInLabel: 'sign_in',
    signInTabTitle: 'sign_in_tab_title',
    signInTitle: 'sign_in_title',
    signOutLabel: 'sign_out',
    continueAsGuestLabel: 'continue_as_guest',
    continueAsGuestTabTitle: 'continue_as_guest_tab_title',
    continueAsGuestTitle: 'continue_as_guest_title',
    guestSignInGdprText: 'guest_sign_in_gdpr',
    addToFavoritesLabel: 'add_to_favorites',
    addedToFavoritesLabel: 'added_to_favorites',
    replenishmentLabel: 'replenishment',
    oneTimeOrderLabel: 'one_time_order',
    autoReplenishLabel: 'auto_replenish',
    signInSignUpTitleLabel: 'sign_in_sign_up',
    showPasswordLabel: 'show_password',
    forgotPasswordLinkAction: 'forgot_password_link_action',
    forgotPasswordLabel: 'forgot_password',
    forgotPasswordActionLabel: 'forgot_password_action',
    passwordLengthRequirementLabel: 'password_length_requirement',
    passwordLowerCharacterLabel: 'password_lower_character',
    passwordUpperCharacterLabel: 'password_upper_character',
    passwordSpecialCharacterLabel: 'password_special_character',
    passwordNumberRequirementLabel: 'password_number_requirement',
    passwordStrength: 'password_strength',
    passwordStrengthRecommendation: 'password_strength_recommendation',
    passwordStrengthRecommendationTitle: 'password_strength_recommendation_title',
    forgotPasswordTitle: 'forgot_password_title',
    forgotPasswordNavTitle: 'forgot_password_nav_title',
    forgotPasswordNavSubtitle: 'forgot_password_nav_subtitle',
    forgotPasswordSmsTitle: 'forgot_password_sms_title',
    forgotPasswordSmsSubtitle: 'forgot_password_sms_subtitle',
    sendOtpBySms: 'send_otp_by_sms',
    oneTimePassword: 'one_time_password',
    switchVerificationMethod: 'switch_verification_method',
    verifyByEmail: 'verify_by_email',
    forgotPasswordSubtitle: 'forgot_password_subtitle',
    backToSignInLabel: 'back_to_sign_in',
    backToSignUpLabel: 'back_to_sign_up',
    backToSiteLabel: 'back_to_site',
    backToForgotPasswordEmailLabel: 'back_to_forgot_password_email',
    forgotPasswordCheckEmailQuestion: 'forgot_password_check_email_question',
    forgotPasswordResetTitle: 'reset_password_title',
    forgotPasswordResetDescription: 'forgot_password_reset_description',
    forgotPasswordResetCheckEmail: 'forgot_password_reset_check_email',
    resetPasswordTitle: 'reset_password_title',
    resetPasswordSubtitle: 'reset_password_subtitle',
    resetPasswordEmailAddress: 'email_address',
    resetPasswordNewPasswordLabel: 'reset_password_new_password',
    resetPasswordRetypePasswordLabel: 'retype_password',
    resetPasswordActionLabel: 'reset_password_action',
    resetPasswordAgainLabel: 'reset_password_again',
    verifyEmailTitle: 'verify_your_email',
    confirmEmailLinkExpiredLabel: 'confirm_email_link_expired',
    expiredLinkCustomerServiceTitle: 'customer_service',
    expiredLinkCustomerServiceMessage: 'expired_link_customer_service_message',
    sessionExpiredTitle: 'session_expired_title',
    sessionExpiredDescription: 'session_expired_description',
    chooseAnotherDefaultAddressTitle: 'choose_another_default_address',
    confirmNewDefaultAddressTitle: 'confirm_new_default_address',
    setDefaultAddressLabel: 'set_default_address',
    forgotPasswordGDPRText: 'forgot_password_gdpr',
    emailConfirmedLabel: 'email_confirmed',
    confirmEmailAddressLabel: 'confirm_email_address',
    confirmEmailForFullAccessLabel: 'confirm_email_for_full_access',
    emailNotConfirmedLabel: 'needs_confirmation',
    changeEmailTitle: 'change_email',
    changeEmailSubtitle: 'change_email_subtitle',
    currentEmailLabel: 'current_email',
    retypeNewEmailLabel: 'retype_new_email',
    newEmailLabel: 'choose_new_email',
    changeEmailDisclaimerLabel: 'change_email_disclaimer',
    validEmailLabel: 'valid_email',
    chooseNewEmailLabel: 'choose_new_email',
    confirmEmailLabel: 'confirm_email',
    resendConfirmationLabel: 'resend_confirmation',
    currentPasswordLabel: 'current_password',
    emailChangedConfirmationLabel: 'email_change_confirmation',
    pleaseCheckYourInboxLabel: 'check_your_inbox',
    emailConfirmationTitle: 'email_confirmation',
    changePasswordTitle: 'change_password',
    changePasswordSubtitle: 'change_password_subtitle',
    newPasswordLabel: 'new_password',
    retypePasswordLabel: 'retype_password',
    passwordChangedConfirmationLabel: 'password_changed_confirmation',
    passwordChangedLabel: 'password_changed',
    firstLineAddressLabel: 'first_line_address',
    secondLineAddressLabel: 'second_line_address',
    thirdLineAddress: 'third_line_address',
    fourthLineAddress: 'fourth_line_address',
    fifthLineAddress: 'fifth_line_address',
    countryLabel: 'country',
    zipCodeLabel: 'zip_code',
    cityLabel: 'city',
    stateLabel: 'state',
    mobileNumberLabel: 'mobile_number',
    smsValidationSent: 'sms_validation_sent',
    defaultAddressLabel: 'default_address',
    companyNameLabel: 'company_name',
    nipLabel: 'nip',
    addNewAddress: 'add_new_address',
    newAddressLabel: 'new_address',
    editAddressTitle: 'edit_address',
    editCheckoutAddressTitle: 'edit_checkout_address',
    deleteAddressTitle: 'delete_address',
    deleteAddressMessage: 'delete_address_message',
    deleteDefaultAddressMessage: 'delete_default_address_message',
    changeImage: 'change_image',
    uploadPhotoInstructions: 'upload_photo_instructions',
    uploadPhotoRecommendations: 'upload_photo_recommendations',
    editProfileTitle: 'edit_profile_title',
    uploadPhotoTitle: 'upload_photo_title',
    accountSettingsTitle: 'account_settings',
    viewUserProfileTitle: 'profile_information',
    viewUserAccountInformationTitle: 'account_information',
    addressBookTitle: 'address_book',
    updateEmailGDPRText: 'update_email_gdpr',
    updateAddressGDPRText: 'update_address_gdpr',
    editUserGDPRText: 'edit_user_gdpr',
    signUpGDPRText: 'sign_up_gdpr',
    privacyPolicyDisclaimerLabel: 'privacy_policy_disclaimer',
    paymentInformation: 'payment_information',
    logoutLabel: 'logout',
    welcomeBack: 'welcome_back',
    loyaltyRewardPoints: 'loyalty_reward_points',
    loyaltyTier1: 'loyalty_tier1',
    loyaltyTier2: 'loyalty_tier2',
    loyaltyTier3: 'loyalty_tier3',
    loyaltyTier4: 'loyalty_tier4',
    facebookLabel: 'facebook',
    noSocialProvidersLabel: 'no_social_providers',
    connectedAccountsTitle: 'connected_accounts_title',
    connectedAccountsDescription: 'connected_accounts_description',
    connectedAccountsMessage: 'connected_accounts_message',
    connectAction: 'connect',
    disconnectAction: 'disconnect',
    continueWithFacebook: 'continue_with_facebook',
    continueWithFacebookPolicy: 'continue_with_facebook_policy',
    continueWithFacebookAdvice: 'continue_with_facebook_advice',
    continueWithFacebookNewsletterPolicyLabel: 'continue_with_facebook_newsletter_policy',
    profileLabel: 'profile',
    settingsLabel: 'settings',
    accountLabel: 'account',
    mandatoryFieldsLabel: 'mandatory_fields',
    maintenanceMessage: 'maintenance_message',
    buyItAgainLabel: 'buy_it_again',
    newToBrand: 'new_to_brand',
    passwordResetInvalidUrlTitle: 'password_reset_invalid_url_title',
    passwordResetInvalidUrlText: 'password_reset_invalid_url_text',
    acceptTermsAndConditionsLabel: 'accept_terms_conditions',
    acceptReceiveNewsletterLabel: 'accept_receive_newsletter',
    acceptLoyaltyTitle: 'accept_loyalty_title',
    acceptLoyaltyLabel: 'accept_loyalty',
    acceptPromotionsLabel: 'accept_promotions',
    acceptLoyaltyDescription: 'accept_loyalty_description',
    optInEmailLabel: 'opt_in_email',
    optInPhoneCallLabel: 'opt_in_phone_call',
    optInSmsLabel: 'opt_in_sms',
    optInDirectMailLabel: 'opt_in_direct_mail',
    optInAllLabel: 'opt_in_all',
    optOutLabel: 'opt_out',
    preferredLanguageLabel: 'preferred_language',
    termsConditionsLoyaltyLabel: 'terms_conditions_loyalty',
    signInOnlineAccountLabel: 'sign_in_online_account',
    createNewRecordLabel: 'create_new_record',
    createNewRecordText: 'create_new_record_text',
    signUpSectionTitle: 'signup_section_title',
    signUpText: 'signup_text',
    createAccountLabel: 'create_account',
    passwordHint: 'password_hint',
    myAccount: 'my_account',
    myOrders: 'my_orders',
    consultations: 'consultations',
    easyReorder: 'easy_reorder',
    subscriptions: 'subscriptions',
    accountUserLogin: 'account_user_login',
    verificationCodeLetterAriaDescription: 'verification_code_letter_aria_description',
    verificationCodeLabel: 'verification_code',
    verifyCodeAction: 'verify_code_action',
    resendVerificationCode: 'resend_verification_code',
    resendVerificationCodeAction: 'resend_verification_code_action',
    signInCodeVerificationTitle: 'sign_in_code_verification_title',
    signInCodeVerificationSubtitle: 'sign_in_code_verification_subtitle',
    signUpCodeVerificationTitle: 'sign_up_code_verification_title',
    signUpCodeVerificationSubtitle: 'sign_up_code_verification_subtitle',
    signUpCodeVerificationMessage: 'sign_up_code_verification_message',
    phoneUserLogin: 'phone_user_login',
    goBindPhoneLabel: 'go_bind_phone',
    bindPhoneTipsTitle: 'bind_phone_tips_title',
    bindPhoneTipsDesc: 'bind_phone_tips_desc',
    bindPhonePageTitle: 'bind_phone_page_title',
    bindPhoneHelpTips: 'bind_phone_help_tips',
    districtLabel: 'district',
    postalCodeLabel: 'postal_code'
};
