export const ProductAttributes = {
    buildableCoverageLabel: 'buildable_coverage',
    mediumfullCoverageLabel: 'mediumfull_coverage',
    mediumbuildableCoverageLabel: 'mediumbuildable_coverage',
    creamFinishLabel: 'cream_finish',
    dewyFinishLabel: 'dewy_finish',
    sheerFinishLabel: 'sheer_finish',
    metallicFinishLabel: 'metallic_finish',
    glossyFinishLabel: 'glossy_finish',
    glitterFinishLabel: 'glitter_finish',
    freshFinishLabel: 'fresh_finish',
    softFinishLabel: 'soft_finish',
    hydratingBenefitLabel: 'hydrating_benefit',
    lightweightBenefitLabel: 'lightweight_benefit',
    plumpingBenefitLabel: 'plumping_benefit',
    volumizingBenefitLabel: 'volumizing_benefit',
    waterproofBenefitLabel: 'waterproof_benefit',
    longLastingBenefitLabel: 'long_lasting_benefit',
    moisturizingBenefitLabel: 'moisturizing_benefit',
    spfBenefitLabel: 'spf_benefit',
    travelBenefitLabel: 'travel_benefit',
    alcoholFreeBenefitLabel: 'alcohol_free_benefit',
    brighteningBenefitLabel: 'brightening_benefit',
    makeupSettingBenefitLabel: 'makeup_setting_benefit',
    curlingBenefitLabel: 'curling_benefit',
    conditioningBenefitLabel: 'conditioning_benefit',
    lengtheningBenefitLabel: 'lengthening_benefit',
    thickeningBenefitLabel: 'thickening_benefit',
    naturalLookBenefitLabel: 'natural_look_benefit',
    frizzfightingBenefitLabel: 'frizzfighting_benefit',
    scalpcareBenefitLabel: 'scalpcare_benefit',
    detanglingBenefitLabel: 'detangling_benefit',
    '2nddayhairBenefitLabel': '2nddayhair_benefit',
    addsshineBenefitLabel: 'addsshine_benefit',
    softeningBenefitLabel: 'softening_benefit',
    smoothingBenefitLabel: 'smoothing_benefit',
    texturingBenefitLabel: 'texturing_benefit',
    soothingBenefitLabel: 'soothing_benefit',
    colorcorrectionBenefitLabel: 'colorcorrection_benefit',
    coverageBenefitLabel: 'coverage_benefit',
    antiwrinkleBenefitLabel: 'antiwrinkle_benefit',
    evenskintoneBenefitLabel: 'evenskintone_benefit'
};
