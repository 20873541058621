import { action } from 'mobx';
import { root } from '@estee/elc-universal-utils';
import { TranslationsApiSdk } from '../../api/TranslationsApiSdk';
import { ITranslationsCollection, ITranslationsCollections } from '@estee/elc-service';
import { ConfigStore } from '../../service-setup/ConfigStore';
import {
    generateLegacyFormat,
    getTranslationsMap,
    translationsCollections
} from '../translation-mappings';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { ELCLogger, TriggerType } from '@estee/elc-logging';

const { name, version } = __serviceInfo__;

export interface ITranslationRepositoryConfig {
    configStore: ConfigStore;
}

export class TranslationsRepository {
    private translations: ITranslationsCollections;
    private translationsApiSdk: TranslationsApiSdk;
    private readonly translationMapping: ITranslationsCollection;
    private config: ITranslationRepositoryConfig;
    private logger: ELCLogger = new ELCLogger({
        serviceName: name,
        environment: root.env,
        buid: root.buid,
        serviceVersion: version
    });

    constructor() {
        this.translations = {};
        this.translationsApiSdk = diContainer.get(serviceNames.translationsApiSdk);
        this.translationMapping = getTranslationsMap();
        this.config = {
            configStore: diContainer.get(serviceNames.configStore)
        };
    }

    /**
     * Get translations for the query provider service
     *
     * @param fieldsRequested Fields to retrieve
     *
     * @return Regular object of key/value pairs with translations
     */
    public getTranslations = async (fieldsRequested: string[]): Promise<{}> => {
        fieldsRequested.sort();

        if (fieldsRequested.length === 0) {
            const error = new Error('Requested translations fields are empty');
            this.logger.error({
                message: error.message,
                triggerType: TriggerType.translation,
                payload: {
                    error
                }
            });

            return {};
        }

        await this.hydrateTranslations(fieldsRequested);

        return fieldsRequested.reduce((accumulator: ITranslationsCollection, field: string) => {
            Object.defineProperty(accumulator, field, {
                value: this.getFieldValue(field),
                writable: false,
                enumerable: true
            });

            return accumulator;
        }, {});
    };

    /**
     * Given a field lookup key (ex. 'product.add_to_bag'), get the hydrated
     * value from the persistent data storage
     *
     * @param field Field lookup key
     */
    private getFieldValue = (field: string): string => {
        const { translations } = this;
        const fieldMap = field in this.translationMapping ? this.translationMapping[field] : false;

        if (!fieldMap) {
            const error = new Error(`Translation field ${field} not found`);
            this.logger.warning({
                message: error.message,
                triggerType: TriggerType.translation,
                payload: {
                    error
                }
            });

            return '';
        }

        const [collectionName, legacyFieldName] = fieldMap.split('.');
        if (collectionName in translations && legacyFieldName in translations[collectionName]) {
            return translations[collectionName][legacyFieldName];
        }

        return '';
    };

    /**
     * Central method of hydrating translations from multiple sources
     *
     * @param fieldsRequested Field names to retrieve
     */
    private hydrateTranslations = async (fieldsRequested: string[]): Promise<void> => {
        const payload = generateLegacyFormat(fieldsRequested, this.translationMapping);

        // Use the payload to set the initial translations dataset
        this.setTranslations(payload);

        // Load collections from window.site.translations, if available
        let data = this.loadFromWindow();
        if (Object.keys(data).length === 0) {
            // Load missing collections from the API and keep them in memory
            data = await this.loadFromApi(Object.keys(translationsCollections));
            root.site = { translations: data };
        }

        this.setTranslations(data);

        // Do final cleanup on the translations datasets
        data = this.cleanTranslations();
        this.setTranslations(data);
    };

    /**
     * Hydrate the translations given a new legacy format dataset
     */
    @action
    private setTranslations(data: ITranslationsCollections): void {
        const currentTranslations = { ...this.translations };
        this.translations = Object.keys({ ...currentTranslations, ...data }).reduce(
            (accumulator: object, collectionName: string) => {
                Object.defineProperty(accumulator, collectionName, {
                    value: {
                        ...currentTranslations[collectionName],
                        ...data[collectionName]
                    },
                    writable: true,
                    enumerable: true
                });

                return accumulator;
            },
            {}
        );
    }

    /**
     * Helper function to clean up empty translation strings
     * This replaces any blank values with ":: <field name> ::"
     */
    private cleanTranslations(): ITranslationsCollections {
        const trans = { ...this.translations };
        const { configStore } = this.config;
        const showPlaceholder =
            configStore.config && configStore.config.showTranslationsPlaceholder;

        const addDefaultVals = (collectionName: string, collection: ITranslationsCollection) => {
            // Get an array of items that have blank keys
            const blanks = Object.keys(collection).filter((field) => !collection[field]);

            // Add ":: <field> ::" values to any missing items based on AppEcomm config
            return blanks.reduce((accumulator, field: string) => {
                Object.defineProperty(accumulator, field, {
                    value: showPlaceholder !== false ? `::${collectionName}.${field}::` : '',
                    writable: true,
                    enumerable: true
                });

                return accumulator;
            }, {});
        };

        return Object.keys(trans).reduce(
            (accumulator: ITranslationsCollections, collectionName: string) => {
                const collection = <ITranslationsCollection>this.getCollection(collectionName);
                const newCollection = <ITranslationsCollection>(
                    addDefaultVals(collectionName, collection)
                );

                accumulator[collectionName] = {
                    ...collection,
                    ...newCollection
                };

                return accumulator;
            },
            {}
        );
    }

    /**
     * Load translations from site.translations.<key>
     * Note: no need to pass the array of collections here since
     *       we just hydrate with all data.
     */
    private loadFromWindow = (): ITranslationsCollections => {
        return root.site && 'translations' in root.site ? root.site.translations : {};
    };

    /**
     * Load translations using the API when all other options have been exhausted
     *
     * @param collections Array of collection names
     */
    private loadFromApi = (collections: string[]) => {
        return this.translationsApiSdk.fetchTranslations(collections);
    };

    /**
     * Retrieve a collection from local variable
     *
     * @param collection The name of the collection to retrieve
     *
     * @return A single collection
     */
    private getCollection(collection: string): ITranslationsCollection {
        const translations = this.translations;

        return collection in translations
            ? translations[collection]
            : TranslationsRepository.defaultCollection();
    }

    /**
     * Default collection data type
     */
    private static defaultCollection(): ITranslationsCollection {
        return {};
    }
}
