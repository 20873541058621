export const General = {
    acceptTermsAndConditionsLabel: 'accept_terms_conditions',
    addAddressToAccountLabel: 'add_address_to_account',
    allLabel: 'all',
    and: 'and',
    application: 'application',
    applyGiftCard: 'apply_gift_card',
    applyLabel: 'apply',
    browseLabel: 'browse',
    fastest: 'fastest',
    californiaPersonalInformationLabel: 'california_personal_information',
    callUsAriaLabel: 'call_us',
    callUsLabel: 'call_us',
    cancelLabel: 'cancel',
    continueLabel: 'continue',
    checkBalance: 'check_balance',
    clearAll: 'clear_all',
    clickingPay: 'clicking_pay',
    closeLabel: 'close',
    commonPrivacyNoticeLabel: 'common_data_privacy_notice',
    confirmLabel: 'confirm',
    contactEmailAriaLabel: 'contact_email',
    contactEmailLabel: 'contact_email',
    contactLabel: 'contact',
    dateLabel: 'date',
    dayLabel: 'day',
    daysLabel: 'days',
    defaultLabel: 'default',
    deleteLabel: 'delete',
    editLabel: 'edit',
    expired: 'expired',
    expiration: 'expiration',
    emailShareLabel: 'email_share',
    feet: 'feet',
    freeLabel: 'free',
    amountLabel: 'amount',
    amountAppliedLabel: 'amount_applied',
    giftCardLabel: 'gift_card',
    friday: 'friday',
    helpfulLinksTitle: 'helpful_links',
    hereLabel: 'here',
    homeLabel: 'home',
    itemLabel: 'item',
    kilometers: 'kilometers',
    learnMore: 'learn_more',
    lesson: 'lesson',
    liveChatAriaLabel: 'live_chat',
    liveChatLabel: 'live_chat',
    loadingLabel: 'loading',
    makeDefaultLabel: 'make_default',
    mediumLabel: 'medium',
    meters: 'meters',
    miles: 'miles',
    min: 'min',
    monday: 'monday',
    monthLabel: 'month',
    moreLabel: 'more',
    navigateToSlide: 'navigate_to_slide',
    nextLabel: 'next',
    nextSlide: 'next_slide',
    nextBusinessDay: 'next_business_day',
    noLabel: 'no',
    okLabel: 'ok',
    openLabel: 'open',
    or: 'or',
    payLabel: 'pay',
    photoLabel: 'photo',
    previewLabel: 'preview',
    previousLabel: 'previous',
    previousSlide: 'previous_slide',
    readMore: 'read_more',
    readLess: 'read_less',
    receiveLabel: 'receive',
    removeLabel: 'remove',
    resetCountdownLabel: 'reset_count_down',
    retryLabel: 'retry',
    reviewPrivacyNoticeLabel: 'review_privacy_notice',
    rewardPointLabel: 'reward_point',
    saturday: 'saturday',
    saveLabel: 'save',
    seeMore: 'see_more',
    selected: 'selected',
    selectLabel: 'select',
    send: 'send',
    shareLabel: 'share',
    strengthLabel: 'strength',
    strongLabel: 'strong',
    submitLabel: 'submit',
    sunday: 'sunday',
    thursday: 'thursday',
    tuesday: 'tuesday',
    unspecifiedLabel: 'unspecified',
    uploadLabel: 'upload',
    weakLabel: 'weak',
    wednesday: 'wednesday',
    withLabel: 'with',
    yearLabel: 'year',
    yesLabel: 'yes',
    welcomeLabel: 'welcome',
    todayLabel: 'today',
    tomorrow: 'tomorrow',
    me: 'me',
    someoneElse: 'someone_else',
    asap: 'asap',
    forLabel: 'for',
    fromLabel: 'from'
};
