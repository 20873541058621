const shippingCodes = ['sd', 'ndd', 'sdd', 'nmdd', 'samd', 'ccd'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shippingCodeTranslations = shippingCodes.reduce((acc: any, shippingCode) => {
    acc[`${shippingCode}Subtitle`] = `${shippingCode}_subtitle`;
    acc[`${shippingCode}Copy`] = `${shippingCode}_copy`;
    acc[`${shippingCode}Icon`] = `${shippingCode}_icon`;
    acc[`${shippingCode}ShippingTimes`] = `${shippingCode}_shipping_times`;

    return acc;
}, {});

const translations = {
    ...shippingCodeTranslations,
    shippingAndHandlingTitle: 'shipping_and_handling_title',
    shippingAndHandlingSubtitle: 'shipping_and_handling_subtitle',
    shippingInformationTitle: 'shipping_information_title',
    shippingPopUpCopy: 'shipping_pop_up_copy',
    shipMethodPopUpTitle: 'ship_method_popup_title',
    costPopUpTitle: 'cost_popup_title',
    shippingTimeFrameTitle: 'shipping_time_frame_title',
    otherLocationsTitle: 'other_locations_title',
    otherLocationsCopy: 'other_locations_copy'
};

export const DeliveryTout = translations;
