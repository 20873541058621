import { Container } from 'hypo-container';
import { HttpTransport } from '@estee/elc-http-transport';
import { TranslationsApiSdk } from '../api/TranslationsApiSdk';
import { ConfigStore } from './ConfigStore';
import { TranslationsRepository } from '../internal/repositories/TranslationsRepository';
import { TranslationsService } from './TranslationsService';
import { ServiceBusController } from './ServiceBusController';

export const diContainer = new Container();
export const serviceNames = {
    configStore: 'CONFIG_STORE',
    httpTransport: 'HTTP_TRANSPORT',
    translationsApiSdk: 'API_SDK',
    translationsRepository: 'TRANSLATIONS_REPOSITORY',
    translationsService: 'TRANSLATIONS_SERVICE',
    serviceBusController: 'SERVICE_BUS_CONTROLLER'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setConfig = (configJSON: any) => {
    diContainer.config = configJSON;
};

diContainer
    .register(
        serviceNames.configStore,
        diContainer.factory((c) => new ConfigStore(c.config))
    )
    .register(serviceNames.httpTransport, (c) => {
        const configStore = c.get(serviceNames.configStore);
        const httpTransport = new HttpTransport();
        httpTransport.setBaseUrl(configStore.baseUrl);

        return httpTransport;
    })
    .register(serviceNames.translationsApiSdk, (c) => {
        const configStore = c.get(serviceNames.configStore);
        const httpTransport = c.get(serviceNames.httpTransport);
        const config = {
            businessUnitId: configStore.config.businessUnitId,
            httpTransport,
            drupalDomain: configStore.config.drupalDomain || ''
        };

        return new TranslationsApiSdk(config);
    })
    .register(serviceNames.translationsRepository, () => new TranslationsRepository())
    .register(
        serviceNames.serviceBusController,
        (c) => new ServiceBusController(c.get(serviceNames.translationsRepository))
    )
    .register(
        serviceNames.translationsService,
        (c) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            new TranslationsService((config: any) => {
                setConfig(config);

                return c.get(serviceNames.serviceBusController);
            })
    );
