export const Communications = {
    emailSenderName: 'email_sender_name',
    emailSender: 'email_sender',
    emailHeaderLogo: 'email_header_logo',
    emailHeaderLink: 'email_header_link',
    emailSafely: 'email_safely',
    emailCheckInBrowser: 'email_check_in_browser',
    emailCheckInBrowserLink: 'email_check_in_browser_link',
    emailContactUs: 'email_contact_us',
    emailContactUsLink: 'email_contact_us_link',
    emailShippingHandling: 'email_shipping_handling',
    emailShippingHandlingLink: 'email_shipping_handling_link',
    emailReturnPolicy: 'email_return_policy',
    emailReturnPolicyLink: 'email_return_policy_link',
    emailTermsCondition: 'email_terms_condition',
    emailTermsConditionLink: 'email_terms_condition_link',
    facebookUrl: 'facebook_url',
    twitterUrl: 'twitter_url',
    youtubeUrl: 'youtube_url',
    instagramUrl: 'instagram_url',
    tumblrUrl: 'tumblr_url',
    emailLegalText: 'email_legal_text',
    unsubscribeLink: 'unsubscribe_link',
    unsubscribeLinkText: 'unsubscribe_link_text',
    emailOptInLabel: 'email_opt_in',
    deliveryHandlingLabel: 'email_shipping_handling',
    deliveryHandlingAriaLabel: 'email_shipping_handling',
    returnPolicyLabel: 'email_return_policy',
    returnPolicyAriaLabel: 'email_return_policy',
    notifyMeLabel: 'notify_me',
    notifyMeSubmitButtonLabel: 'notify_me_submit',
    notifyMeConfirmationTitle: 'notify_me_confirmation_title',
    notifyMeConfirmationText: 'notify_me_confirmation_text',
    notifyMePopUpHeaderText: 'notify_me_popup_header_text',
    notifyMeTitleLabel: 'notify_me_title',
    notifyMeDescriptionLabel: 'notify_me_description',
    continueShoppingButtonLabel: 'continue_shopping',
    gdprValidationText: 'gdpr_validation',
    contactPreferenceTitle: 'contact_preference_title',
    contactPreferenceContent: 'contact_preference_content'
};
