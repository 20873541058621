export const Cart = {
    addToBagButtonLabel: 'add_to_bag',
    shoppingBagTitle: 'shopping_bag',
    loginIncentive: 'login_incentive',
    addedToCartLabel: 'added_to_cart',
    // order summary
    orderSummaryTitle: 'order_summary',
    total: 'total',
    totalEstimate: 'total_estimate',
    subtotal: 'subtotal',
    discount: 'discount',
    discouns: 'discouns',
    shippingLabel: 'shipping',
    shippingTo: 'shipping_to',
    billingTo: 'billing_to',
    vatIncludedLabel: 'vat_included',
    estimatedVatIncludedLabel: 'estimated_vat_included',
    countryDeliveryTaxesLabel: 'country_delivery_taxes',
    totalDiscountsAppliedLabel: 'discount_applied',
    salesTaxLabel: 'sales_tax',
    taxesInfoLabel: 'taxes_info',
    pstTaxLabel: 'pst_tax',
    hstTaxLabel: 'hst_tax',
    gstTaxLabel: 'gst_tax',
    // cart items
    itemDiscountPercentageLabel: 'item_discounted_value',
    saveForLaterLabel: 'save_for_later',
    itemCartSavedForLaterLabel: 'was_saved_for_later',
    itemCartRemovedLabel: 'was_removed_from_cart',
    itemCartOutOfStockLabel: 'item_out_of_stock',
    itemCartAddBackToCartLabel: 'add_back_to_cart',
    quantityLabel: 'quantity',
    // cart merge
    cartMergeTitleLabel: 'cart_merge_title',
    cartMergeSubTitleLabel: 'cart_merge_subtitle',
    acceptMergeButtonLabel: 'accept_merge',
    declineMergeButtonLabel: 'decline_merge',
    // cart empty
    shopBestsellersButtonText: 'shop_best_sellers',
    goShoppingLabel: 'go_shopping',
    homepageButtonText: 'shop_home_page',
    // shopping bag screen modules
    accountLogin: 'account_login',
    savedForLaterListTitle: 'saved_for_later',
    addFreeSamples: 'add_free_samples',
    wpAddSamplesTitle: 'add_with_purchase_samples_title',
    chooseSamplesMessage: 'choose_free_samples',
    productSampleLabel: 'product_sample',
    congratulationsCartTabOfferLabel: 'congratulations_cart_tab_offer',
    // offers
    offerCodeLabel: 'offer_code',
    applyOfferCodeLabel: 'apply_offer_code',
    offerLinksLabel: 'see_all_offers',
    appliedOfferLabel: 'applied_offer',
    // actions
    standardShippingLabel: 'standard_shipping',
    standardShippingOverlayText: 'standard_shipping_message',
    clickAndCollectShipping: 'click_and_collect_shipping',
    clickAndCollectShippingNumberOfDays: 'click_and_collect_shipping_number_of_days',
    secondDayShippingLabel: 'second_day_shipping',
    secondDayShippingOverlayText: 'second_day_shipping_message',
    overnightShippingLabel: 'overnight_shipping',
    overnightShippingOverlayText: 'overnight_shipping_message',
    electronicShippingLabel: 'electronic_shipping',
    electronicShippingOverlayText: 'electronic_shipping_message',
    freeShippingLabel: 'free_shipping_label',
    freeReturnsLabel: 'free_returns_label',
    maximumPurchasingPolicy: 'maxium_purchasing_policy',
    keepShoppingToQualify: 'keep_shopping_to_qualify',
    proceedToCheckout: 'proceed_to_checkout',
    applyCartChanges: 'apply_cart_changes',
    returnToCart: 'return_to_cart',
    // recommended section
    recommendedProductsTitle: 'recommended_for_you',
    // account module
    youAreLoggedAs: 'you_are_logged_in_as',
    continueAsGuestMessage: 'continue_as_guest_message',
    // donations module
    donationLabel: 'donation',
    makeADonation: 'make_a_donation',
    donationTo: 'donation_to',
    donationAmountLabel: 'donation_amount',
    giftWrapLabel: 'gift_wrap',
    yourMessageLabel: 'your_message',
    priceLabel: 'price',
    preOrderNoChargeLabel: 'pre_order_no_charge',
    preOrderItemLabel: 'pre_order_item',
    redeemGiftCardLabel: 'redeem_gift_card',
    certificateNumberLabel: 'certificate_number',
    certificatePinLabel: 'certificate_pin',
    engravingLabel: 'engraving',
    monogrammingLabel: 'monogramming'
};
