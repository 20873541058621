(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("HttpTransport"), require("ElcServiceBus"), require("HypoContainer"), require("mobx"), require("react"));
	else if(typeof define === 'function' && define.amd)
		define(["HttpTransport", "ElcServiceBus", "HypoContainer", "mobx", "react"], factory);
	else if(typeof exports === 'object')
		exports["elc-service-translations"] = factory(require("HttpTransport"), require("ElcServiceBus"), require("HypoContainer"), require("mobx"), require("react"));
	else
		root["elc-service-translations"] = factory(root["ElcRuntime"]["HttpTransport"], root["ElcServiceBus"], root["ElcRuntime"]["HypoContainer"], root["ElcRuntime"]["mobx"], root["ElcRuntime"]["React"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__estee_elc_http_transport__, __WEBPACK_EXTERNAL_MODULE__estee_elc_service_bus__, __WEBPACK_EXTERNAL_MODULE_hypo_container__, __WEBPACK_EXTERNAL_MODULE_mobx__, __WEBPACK_EXTERNAL_MODULE_react__) {
return 